<template>

    <nuxt-link :to="{ path: '/goods', query: { id: goods.goodsId, inflowMethod: inflowMethod } }">
      <!-- <figure class="figure"> -->
      <div class="thumb" style="aspect-ratio: 10 / 13;">
        <!--img :src="getImageUrl(goods.imgPath)" onerror="this.src='/images/no-img.jpg'"/-->
        <img :src="rsImgPath" style="aspect-ratio: 10 / 13;"  onerror="this.src='/images/no-img.jpg'" :alt="`${goods.goodsNm} 상품썸네일 이미지`"/>
      </div>
      <!-- <figcaption class="figcaption"> -->
      <div class="pro_sminfo">
        <div class="pro_top">
            <span class="sort_tit">{{ (genderStr) }}</span>
            <div class="color_chip sm" v-if="isShowColorWarp">
                <template v-for="(item, itemIndex) in getColorInfo" v-if="itemIndex < 6">
                    <span :key="`item_${itemIndex}`" :style="{ 'background-color': getSplitData(item, '|', 1) }" v-if="['#ZZZZZZ'].indexOf(getSplitData(item, '|', 1)) < 0 && ['Multi','MultiColor'].indexOf(getSplitData(item, '|', 0)) < 0"></span>
                    <span :key="`item_${itemIndex}-m`" class="multi" v-else></span>
                </template>
                <template v-if="goods.rgbVal">
                    <span :style="{ 'background-color': goods.rgbVal }" v-if="['#822882','#ZZZZZZ'].indexOf(goods.rgbVal) < 0"></span>
                    <span class="multi" v-if="['#822882','#ZZZZZZ'].indexOf(goods.rgbVal) >= 0"></span>
                </template>
                <span :key="`item_${itemIndex}-cnt`" class="colorCnt" v-if="getColorInfo.length > 6">+{{ getColorCnt(getColorInfo.length, 6) }}</span>
            </div>
        </div>
        <p class="pro_name">{{ goods.goodsNm }}</p>
        <div class="price">
          <span class="dis_per" v-if="dcAmt > 0">{{ formatPercent(dcAmt / saleAmt) }}</span>
          <span class="org_pri" >{{ formatAmount(saleAmt - dcAmt) }}</span>
          <span class="dis_pri" v-if="dcAmt > 0">{{ formatAmount(saleAmt) }}</span>
        </div>
      </div>
      <!-- </figcaption>
      </figure> -->
    </nuxt-link>

</template>

<script>
import Desktop from "@/components/goods/default/Desktop.vue";

export default {
  extends: Desktop
};
</script>
