<template>
  <nuxt-link :to="{ path: '/goods', query: { id: goods.goodsId, inflowMethod: inflowMethod } }">
    <div class="thumb"  @mouseover="hover=true" @mouseleave="hover=false">
      <img :src="rsImage" onerror="this.src='/images/no-img.jpg'" :alt="`${goods.goodsNm} 상품썸네일 이미지`" v-if="hover==false">
      <img :src="rsImage2" onerror="this.src='/images/no-img.jpg'" :alt="`${goods.goodsNm} 상품썸네일 hover 이미지`" v-else>
    </div>
    <div class="pro_sminfo">
      <div class="info_top_wrap">
        <span class="sort_tit">{{ (genderStr) }}</span>
        <div class="color_chip sm" v-if="isShowColorWarp">
          <template v-for="(item, itemIndex) in getColorInfo" v-if="itemIndex < 6" >
            <span :key="`item_${itemIndex}`" :class="itemIndex == 5?'last_color':''" :style="{ 'background-color': getSplitData(item, '|', 1) }" v-if="['#822882','#ZZZZZZ'].indexOf(getSplitData(item, '|', 1)) < 0"></span>
            <span :key="`item_${itemIndex}-m`" class="multi" v-if="['#822882','#ZZZZZZ'].indexOf(getSplitData(item, '|', 1)) >= 0"></span>
          </template>
          <template v-if="goods.rgbVal">
            <span :style="{ 'background-color': goods.rgbVal }" v-if="['#822882','#ZZZZZZ'].indexOf(goods.rgbVal) < 0"></span>
            <span class="multi" v-if="['#822882','#ZZZZZZ'].indexOf(goods.rgbVal) >= 0"></span>
          </template>
          <span :key="`item_cnt`" class="colorCnt" v-if="getColorInfo.length > 6">+{{ getColorCnt(getColorInfo.length, 6) }}</span>
        </div>
      </div>
      <p class="pro_name" v-if="goods.prmtNm">{{ goods.prmtNm }}</p>
      <p class="pro_name">{{ goods.goodsNm }}</p>
      <div class="price">
        <span class="dis_per" v-if="dcAmt > 0">{{ formatPercent(dcAmt / saleAmt) }}</span>
        <span class="org_pri">{{ formatAmount(saleAmt - dcAmt) }}</span>
        <span class="dis_pri" v-if="dcAmt > 0">{{ formatAmount(saleAmt) }}</span>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import numeral from "numeral";

export default {
  props: {
    goods: Object,
    inflowMethod : {
      type: String,
      required: false
    }
  },
  data() {
    return {
      rsImgPath: this.goods.imgPath,
      rsImgPath2: this.goods.imgItemPath2,
      hover: false,
      rsWidth: 600,
    }
  },

  created () {

      this.rsImgPath = this.goods.imgPath,
      this.rsImgPath2 = this.goods.imgItemPath2;

  },

  updated() {
    if(!this.rsWidth) {
      if(this.$device.isMobile){
        //20231110 모바일도 400으로 변경
        this.rsWidth = 400;
      } else {
        this.rsWidth = 600;
      }
    }

    try {
      if( this.goods ){
        this.rsImgPath = this.getImageUrlRS(this.goods.imgPath, this.rsWidth);
        this.rsImgPath2 = this.getImageUrlRS((this.goods.imgItemPath1  ? this.goods.imgItemPath1 : this.goods.imgItemPath2) || this.goods.imgPath, this.rsWidth);
      } else {
        this.rsImgPath = this.getImageUrl(this.goods.imgPath);
        this.rsImgPath2 = this.getImageUrl((this.goods.imgItemPath1 ? this.goods.imgItemPath1 : this.goods.imgItemPath2) || this.goods.imgPath);
      }
    } catch (e) {
      this.rsImgPath = this.goods.imgPath;
      this.rsImgPath2 = (this.goods.imgItemPath1  ? this.goods.imgItemPath1 : this.goods.imgItemPath2) || this.goods.imgPath;
    }
  },

  mounted() {

    //CDN에 RS파라미터 넘기기
    if(!this.rsWidth) {
      if(this.$device.isMobile){
        this.rsWidth = 250;
      } else {
        this.rsWidth = 600;
      }
    }

    try {
      if( this.goods ){
        this.rsImgPath = this.getImageUrlRS(this.goods.imgPath, this.rsWidth);
        this.rsImgPath2 = this.getImageUrlRS(this.goods.imgItemPath1  ? this.goods.imgItemPath1 : this.goods.imgItemPath2, this.rsWidth);
      } else {
        this.rsImgPath = this.getImageUrl(this.goods.imgPath);
        this.rsImgPath2 = this.getImageUrl(this.goods.imgItemPath1 ? this.goods.imgItemPath1 : this.goods.imgItemPath2);
      }
    } catch (e) {
      this.rsImgPath = this.goods.imgPath;
      this.rsImgPath2 = this.goods.imgItemPath1  ? this.goods.imgItemPath1 : this.goods.imgItemPath2;
    }
  },

  computed: {
    rsImage() {
      return this.rsImgPath
    },
    rsImage2() {
      return this.rsImgPath2
    },
    saleAmt() {
      if (this.goods.orgAmtHiddenYn != "Y") {
        return numeral(this.goods.orgSaleAmt).value();
      } else {
        return numeral(this.goods.saleAmt).value();
      }
    },
    offDcAmt() {
      let orgSaleAmt = numeral(this.goods.orgSaleAmt).value();
      let saleAmt = numeral(this.goods.saleAmt).value();
      if (this.goods.orgAmtHiddenYn != "Y") {
        return orgSaleAmt - saleAmt;
      } else {
        return 0;
      }
    },
    dcAmt() {
      let prmtDcAmt = numeral(this.goods.prmtDcAmt).value();
      return this.offDcAmt + prmtDcAmt;
    },
    genderStr() {
      if (this.goods.gndrKd == "M") {
        return "MEN";
      } else if (this.goods.gndrKd == "F") {
        return "WOMEN";
      } else if (this.goods.gndrKd == "K") {
        return "KIDS"
      } else if (this.goods.gndrKd == "Z") {
        return "UNISEX";
      } else {
        return "";
      }
    },
    isShowColorWarp() {
      // this.isShowOption = this.isNoneOptions
      if (this.isNoneOptions !== undefined && this.isNoneOptions.indexOf("color")> -1) {
        return false
      }
      return true;
    },
    getColorInfo() {
      var comma = this.splitComma(this.goods.colorInfo).filter(x => x != '');
      return comma;
      if(comma.length > 6){
        var cutComma = comma.slice(0, 6);
        return cutComma;
      }else {
        return comma;
      }
    },
    estmScore(){
      let estmScore = numeral(this.goods.estmScore).value() * 0.2;
      return estmScore;
    },
    
  },
  methods:{
    getColorCnt(cnt, len){
        var count = cnt - len
        return count;
    },
  }
};
</script>
