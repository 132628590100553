<template>
   <div class="scrollbar_proli best_goods mg10" style="aspect-ratio: 343/420;">

    <client-only>
    <swiper ref="swiperOptions" :options="goodsList.length == 1 ? Object.assign(swiperOptions, { init:false}) : swiperOptions" class="swiper-wrapper">
    <div class="swiper-slide" v-for="(goods, goodsIndex) in goodsList" :key="`goods-${goodsIndex}`">
      <swiper-slide >
    <Goods :goods="goods" :inflow-method="inflowMethod"></Goods>
  <!--<goods :goodsList="goodsList" :goods="goods"></goods>-->
   </swiper-slide>
   </div>
   <!-- <div class="swiper-scrollbar" slot="scrollbar"></div> -->
    </swiper>
    </client-only>

  </div>
</template>

<script>
import formatter from 'assets/mixins/formatter';
import Goods from "@/components/main/mobile/Goods.vue";
import BestGoodsList from "../desktop/BestGoodsList.vue";
export default {
  name: "GoodsList",
  components: { Goods },
  extends: BestGoodsList,
  mixins: [formatter],
  props: {
    goodsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      wishList: [],
      swiperOptions: {
        slidesPerView: 1.3,
        slidesPerGroup: 1,
        spaceBetween: 12,
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        //   hide: false,
        // },
      },
    }
  },

};

</script>
<style lang="css" scoped>
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
    left: 0;
    bottom: 3px;
    z-index: 50;
    height: 2px;
    width: 98%;
    width : calc(100% - 93px);
    background: rgba(0, 0, 0, 0.1);
}
</style>
