<template>
  <!-- s : 20220311 카드배너 추가 -->
  <div class="card_banner"  style="margin-top: -100px;">
    <a :href="getUrl(banner.bnrLinkUrl)" v-for="(banner, bannerIndex) in banners" :key="`banner-${bannerIndex}`">
      <div class="pos_txt">
        <p v-html="banner.bnrText"></p>
        <span v-html="banner.bnrHtml"></span>
      </div>
      <img :src="getImageUrlRS(banner.bnrMobileImgPath, 400)" onerror="this.src='/images/no-img.jpg'" alt="" />
    </a>
  </div>
  <!-- e : 20220311 카드배너 추가 -->
</template>

<script>
import banner from '@/components/main/banner';
import Formatter from "@/assets/mixins/formatter";

export default {
  name: "ImgBanner",
  mixins: [banner, Formatter],
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
        spaceBetween: 1,
      },
    }
  },
};
</script>

<style></style>
