<template>
  <section class="wrapper best_mar_set">
      <div class="main_ctg_wrap">
        <div class="ctg_wrap" v-for="(gender, idx) in genderList" :key="`gender-${idx}`">
          <img :src="gender.img" alt="category_img">
          <div class="ctg_txt_wrap">
            <p class="ctg_ttl">{{ gender.ttl }}</p>
            <div class="ctg_btn_wrap">
              <a :href="gender.url" v-for="(btn, idx) in gender.btn" :key="`btn-${idx}`">{{ btn }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="main_ctg_wrap mgt20">
        <div class="ctg_wrap" v-for="(banner, idx) in shopBanners" :key="`gender-${idx}`">
          <img :src="getImageUrlRS(banner.bnrImgPath, 700)" alt="category_img">
          <div class="ctg_txt_wrap">
            <p class="ctg_ttl">{{ banner.bnrText }}</p>
            <div class="ctg_btn_wrap">
              <!--a :href="btn.url" v-for="(btn, idx) in ctg.btn" :key="`btn-${idx}`">{{ btn.btn }}</a-->
              <a :href="getUrl(banner.bnrBtnUrl1)" v-if="banner.bnrBtnCnt >= 1">{{ banner.bnrBtnNm1 }}</a>
              <a :href="getUrl(banner.bnrBtnUrl2)" v-if="banner.bnrBtnCnt >= 2">{{ banner.bnrBtnNm2 }}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
  import Formatter from "@/assets/mixins/formatter";

  export default {
    name: "TodayCardSection",
    mixins: [Formatter],

    props: {

    },
    data() {
      return {
        genderList: [
          {
            img: 'https://img.lottegfr.co.kr/K-WAY/BRAND-MALL/cate_banner/men_pc.jpg',
            ttl: "Men",
            btn: ["shop"],
            url: "/category?id=10&gender=M",
          },
          {
            img: 'https://img.lottegfr.co.kr/K-WAY/BRAND-MALL/cate_banner/women_pc.jpg',
            ttl: "Women",
            btn: ["shop"],
            url: "/category?id=10&gender=F",
          },
          {
            img: 'https://img.lottegfr.co.kr/K-WAY/BRAND-MALL/cate_banner/kids_pc.jpg',
            ttl: "Kids",
            btn: ["shop"],
            url: "/category?id=10&gender=K",
          },
        ],
        shopBanners : [],
        limitShopBannerLength : 2,
      }
    },
    async fetch() {
      await this.fetchBanners();
    },
    methods: {
      async fetchBanners() {
        this.shopBanners = await this.$axios.$get(`/api/main/800900113/banners`);

        let checkBannerGenderArr = ['00'];
        if(this.$device.isMobile) {
            checkBannerGenderArr.push("20");
        } else {
            checkBannerGenderArr.push("10");
        }

        this.shopBanners = this.shopBanners.filter(banner => checkBannerGenderArr.indexOf(banner.bnrWebMobileGbCd) >= 0 );

        if(this.shopBanners && this.shopBanners.length > this.limitShopBannerLength) {
          this.shopBanners = this.shopBanners.slice(0, this.limitShopBannerLength);
        }
      },
    },
  };

</script>