export default {
  async fetch() {
    await this.fetchBanners();
  },
  props: {
    category: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      banners: [],
    };
  },
  methods: {
    async fetchBanners() {
      // console.log(`/api/main/${this.category.categoryNo}/banners`)
      let response = await this.$axios.$get(`/api/main/${this.category.categoryNo}/banners`)

      if (this.$device.isMobile) {
        response = response.filter(banner => {
          return banner.bnrWebMobileGbCd === '00' || banner.bnrWebMobileGbCd === '20'
        })
      } else {
        response = response.filter(banner => {
          return banner.bnrWebMobileGbCd === '00' || banner.bnrWebMobileGbCd === '10'
        })
      }

      this.banners = response
      // this.banners = await this.$axios.$get(`/api/main/${this.category.categoryNo}/banners`);
    },
  },
};

