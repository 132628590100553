<template>
  <client-only>
    <!-- <swiper ref="swiperOptions" :options="goodsList.length == 1 ? Object.assign(swiperOptions, { init:false}) : swiperOptions" class="swiper-wrapper">
      <swiper-slide class="swiper-slide" v-for="(goods, goodsIndex) in goodsList" :key="`goods-${goodsIndex}`">
        <Goods :goods="goods" inflow-method="MAINSPECIAL"></Goods>
      </swiper-slide>
      <div class="swiper-scrollbar" slot="scrollbar"></div>
    </swiper> -->
    <ul class="spec_list_wrap">
        <li v-for="(goods, goodsIndex) in goodsList" :key="`goods-${goodsIndex}`" v-if="goodsIndex < 4">
            <Goods :goods="goods" inflow-method="MAINSPECIAL"></Goods>
        </li>
    </ul>
  </client-only>




  <!-- <ul class="item_comonli col4">
    <goods v-for="(goods, goodsIndex) in goodsList"
           :key="`goods-${goodsIndex}`" :goods="goods">
    </goods>
  </ul> -->
</template>

<script>
import formatter from 'assets/mixins/formatter';
import Goods from "@/components/main/desktop/SpecialGoods.vue";

export default {
  name: "GoodsList",
  components: { Goods },
  mixins: [formatter],
  props: {
    goodsCategory: Number,
    goodsList: {
      type: Array,
      default: () => Array
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      //goodsList: []
    }
  },
  /*
  async fetch() {
    await this.fetchGoodsList();
  },
  methods: {
     async fetchGoodsList() {
      this.goodsList = await this.$axios.$get(`/api/main/${this.goodsCategory}/goods`);
    },
  }
  */
};

</script>

<style lang="css" scoped>
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 0;
  bottom: 3px;
  z-index: 50;
  height: 2px;
  width : calc(100% - 93px);
}
.scrollbar_proli .swiper-scrollbar{top:369px !important;}
</style>

