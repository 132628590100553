<template>
<client-only>
     <swiper ref="swiperOptions" :options="swiperOptions" class="swiper-wrapper">
        <swiper-slide class="swiper-slide" v-for="(goods, goodsIndex) in goodsList" :key="`goods-${goodsIndex}`">
           <SpecialGoods :goods="goods" inflow-method="MAINSPECIAL"></SpecialGoods>
        </swiper-slide>
       <!-- <div class="swiper-scrollbar" slot="scrollbar"></div> -->
    </swiper>
</client-only>
</template>

<script>
import SpecialGoods from "@/components/main/mobile/SpecialGoods.vue";
import formatter from 'assets/mixins/formatter';
export default {
  components: {
      SpecialGoods,
  },
  mixins:[formatter],
  props: {
    goodsCategory: Number,
    goodsList: {
      type: Array,
      default: () => Array
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 2.5,
        slidesPerGroup: 1,
        spaceBetween: 11,
        /*
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
        },
        */
      },
      //goodsList: []
    }
  },
  /*
  async fetch() {
    await this.fetchGoodsList();
  },
  methods: {
     async fetchGoodsList() {
      this.goodsList = await this.$axios.$get(`/api/main/${this.goodsCategory}/goods`);
    },
  }
  */
}
</script>
<style lang="css" scoped>
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 0;
  bottom: -10px;
  z-index: 50;
  height: 2px;
  width : calc(100% - 93px);
}
</style>
