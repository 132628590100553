<template>
  <section class="wrapper"  style="margin-top: -200px;"> <!--이미지 배너 숨김으로 공백 -200마진 줌 20230428 김태곤-->
    <ul class="theme_link main_para">
      <li v-for="(banner, bannerIndex) in banners" :key="`banner-${bannerIndex}`">
        <a :href="getUrl(banner.bnrLinkUrl)">
          <img :src="getImageUrlRS(banner.bnrImgPath, 400)" onerror="this.src='/images/no-img.jpg'" alt="" />
          <div class="pos_txt">
            <strong v-html="banner.bnrText"></strong>
            <p v-html="banner.bnrHtml"></p>
          </div>
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
  import banner from '@/components/main/banner';
  import Formatter from "@/assets/mixins/formatter";

  export default {
    name: "ImgBanner",
    mixins: [banner, Formatter],

    props: {
      category: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        swiperOptions: {
          slidesPerView: "auto",
          spaceBetween: 0,
          scrollbar: {
            el: ".img-swiper-scrollbar",
            hide: true,
          },
        },
      };
    },
    mounted() {
      // console.log(this.banners)
    }
  };

</script>

<style></style>
