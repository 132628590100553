<template>
  <ul class="item_comonli col4">
    <goods v-for="(goods, goodsIndex) in goodsList"
           :key="`goods-${goodsIndex}`" :goods="goods" :inflow-method="inflowMethod">
    </goods>
  </ul>
</template>

<script>
import formatter from 'assets/mixins/formatter';
import Goods from "@/components/goods/default/Desktop.vue";

export default {
  name: "GoodsList",
  components: { Goods },
  mixins: [formatter],
  props: {
    goodsList: {
      type: Array,
      default: () => []
    },
    inflowMethod : {
      type: String,
      required: false
    }
  },
  data() {
    return {
      wishList: [],
      swiperOptions: {
        slidesPerView: 4.9,
        slidesPerGroup: 1,
        spaceBetween: 1,
      },
    }
  },
  async fetch() {
    await this.getWishList();
  },

  watch: {
    async 'goodsList'() {
      await this.$nextTick();
      window.crema ? window.crema.run() : window.cremaAsyncInit();
    }
  },

  methods: {
    async addWishList(goods) {
      await this.$axios.$post('/api/category/saveMbrIntrGoods', goods);
      await this.getWishList();
    },
    async deleteWishList(goods) {
      await this.$axios.$post('/api/category/delMbrIntrGoods', goods);
      await this.getWishList();
    },
    async getWishList() {
      this.wishList = await this.$axios.$get('/api/main/wish-list/ids');
    },
  },
};

</script>
