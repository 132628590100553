<template>
<div class="le_vraicol">
  <div class="wrapper">
          <div class="main_tit">
            <h3>LE VRAI COLLECTION</h3>
            <p class="md">1965년 탄생한 세계 최초의 윈드 브레이커이자<br />K-WAY(까웨)의 대표 컬렉션 Le Vrai(르 브레)<br />50년째 꾸준히 사랑받는 까웨의 대표 라인</p>
            <div class="hash_txt">
              <span>가볍고 편안한 착용감</span>
              <span>완벽한 방수</span><br>
              <span>쉬운 휴대</span>
            </div>
          </div>
    <div class="le_vrswiper">
      <client-only>
        <swiper ref="swiperOptions" :options="banners.length == 1 ? Object.assign(swiperOptions, { init:false}) : swiperOptions" >
          <swiper-slide  v-for="(banner, bannerIndex) in banners" :key="`banner-${bannerIndex}`">
            <a :href="getUrl(banner.bnrLinkUrl) + '&inflowMethod=MAINPEOPLE'">
              <img :src="getImageUrlRS(banner.bnrImgPath, 400)" onerror="this.src='/images/no-img.jpg'" alt="">
                <div class="pos_txt">
                   <p class="pro_name" v-html="nl2br(banner.bnrHtml)"></p>
                </div>
            </a>
          </swiper-slide>    
          <div class="swiper-scrollbar" slot="scrollbar"></div>
        </swiper>
      </client-only>
    </div>
  </div>
</div>
</template>

<script>
import banner from '@/components/main/banner';
import Formatter from 'assets/mixins/formatter';

export default {
  components: {
    // PeopleList,
  },

  mixins: [banner, Formatter],
  props: {
    category: {
      type: Object,
      default: () => Array
    },
  },
  data() {
    return{
      swiperOptions: {
        slidesPerView: 1.3,
        slidesPerGroup: 1,
        spaceBetween: 15,
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
        }
      },
    }
  },
  computed:{
  },
  mounted() {
    // console.log("this.category", this.category)
    // console.log("this.banners", this.banners)
  },

  methods: {
    nl2br(str) {
      if(!str){
        return ''
      }
      return str.replace(/\r\n|\n/g, '<br />')
    }
  }

};
</script>

<style lang="css" scoped>
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 50;
  height: 2px;
  width : calc(100% - 93px);
}
.le_vraicol .swiper-container{overflow:visible !important}
.le_vrswiper .swiper-scrollbar{background:rgba(255, 255, 255, 0.3) !important;}
.le_vrswiper .swiper-scrollbar-drag{background:rgba(255, 255, 255, 0.5) !important;}
</style>