<template>
  <div class="main_page">
    <Snow v-if="snowYn == 'Y'"></Snow>
    <Rain v-if="rainYn == 'Y'"></Rain>
    <VisualBanner :category="category('800900101')"></VisualBanner>
    <!-- LE VRAI COLLECTION -->
    <!-- <People :category="category('800900109')"></People> -->
    <component :is="`BestGoods`" :category="category('800900102')" :menutitle="`NEW`"></component>
    <!--component :is="`BestGoods`" :category="{ categoryNo : 800900999 }" :menutitle="`NEW`"></component-->

    <Special :category="category('800900104')"></Special>
    <!-- <ImgBanner :category="category('800900108')"></ImgBanner> -->
    <!-- <component :is="`BestGoods`" :category="category('800900103')" :menutitle="`BEST`"></component> -->
    <TodayCardSection></TodayCardSection>

    <!--section class="best_review main_para wrapper">
      <div class="main_contit">
        <strong>Best Review</strong>
      </div>
      <div class="rviw_licon">
        --<img src="/web/images/pic_rvwlicon.jpg" alt=""> 해당라인 주석
        cre.ma / 갤러리 위젯 / 스크립트를 수정할 경우 연락주세요 (support@cre.ma)--
        <div class="crema-reviews" data-widget-id="28"></div>
      </div>
      <nuxt-link :to="`/main/review`" class="more_link" aria-label="리뷰 더보기 이동">view all</nuxt-link>
    </section-->
    <!-- cre.ma / 리뷰 작성 유도 팝업 / 스크립트를 수정할 경우 연락주세요 (support@cre.ma) -->
    <div class="crema-popup"></div>
  </div>
</template>
<script>

  import VisualBanner from "@/components/main/desktop/VisualBanner.vue";
  import People from "@/components/main/desktop/People.vue";
  import Special from "@/components/main/desktop/Special.vue";
  import BestGoods from "@/components/main/desktop/BestGoods.vue";
  // import TrendList from "@/components/main/desktop/TrendList.vue";
  // import FavoriteMenu from "@/components/main/desktop/FavoriteMenu.vue";
  // import ReviewList from "@/components/main/desktop/ReviewList.vue";
  import ImgBanner from "@/components/main/desktop/ImgBanner.vue";
  import Snow from "@/components/main/desktop/Snow"
  import Rain from "@/components/main/desktop/Rain"
  import TodayCardSection from "@/components/main/desktop/TodayCardSection.vue";

  export default {

    components: {
      VisualBanner,
      People,
      Special,
      // FavoriteMenu,
      BestGoods,
      // TrendList,
      // ReviewList,
      ImgBanner,
      Snow,
      Rain,
      TodayCardSection,
    },
    props: {
      categories: {
        type: Array
      }
    },
    data() {
      return {
        snowYn: 'N',
        rainYn: 'N'
      }
    },

    async mounted() {
      this.snowYn = await this.$axios.$get("/api/main/snowstatus");
      this.rainYn = await this.$axios.$get("/api/main/rainstatus");
    },

    methods: {
      category(categoryNo) {
        // console.log("this.categories", this.categories);
        return this.categories.find(x => x.categoryNo == categoryNo);
        // return this.categories.filter(x => x.type === type);
      },
      imgBnr(bnr) {
        return bnr;
      },
    }
  };
</script>
