<template>
  <nuxt-link :to="{ path: '/goods', query: { id: goods.goodsId, inflowMethod: inflowMethod } }">
    <div class="thumb" style="aspect-ratio: 10 / 13;">
      <img :src="getImageUrlRS(goods.imgPath, 400)" style="aspect-ratio: 10 / 13;" onerror="this.src='/images/no-img.jpg'" :alt="`${goods.goodsNm} 상품썸네일 이미지`">
    </div>
    <div class="pro_sminfo">
        <div class="goods_top">
            <span class="sort_tit">{{ (genderStr) }}</span>
            <!-- <div class="color_chip sm" v-if="isShowColorWarp">
                <template v-for="(item, itemIndex) in getColorInfo">
                    <span :key="`item_${itemIndex}`" :style="{ 'background-color': getSplitData(item, '|', 1) }" v-if="['#ZZZZZZ'].indexOf(getSplitData(item, '|', 1)) < 0 && ['Multi','MultiColor'].indexOf(getSplitData(item, '|', 0)) < 0"></span>
                    <span :key="`item_${itemIndex}-m`" class="multi" v-else></span>
                </template>
                <template v-if="goods.rgbVal">
                    <span :style="{ 'background-color': goods.rgbVal }" v-if="['#822882','#ZZZZZZ'].indexOf(goods.rgbVal) < 0"></span>
                    <span class="multi" v-if="['#822882','#ZZZZZZ'].indexOf(goods.rgbVal) >= 0"></span>
                </template>
                <span :key="`item_${itemIndex}-cnt`" class="colorCnt" v-if="getColorNum(getColorInfo.length, 6)">+ {{ getColorCnt(getColorInfo.length) }}</span>
            </div> -->
        </div>
      <p class="pro_name" v-if="goods.prmtNm">{{ goods.prmtNm }}</p>
      <p class="pro_name">{{ goods.goodsNm }}</p>
      <div class="price">
        <span class="org_pri">{{ formatAmount(saleAmt - dcAmt) }}</span>
        <span class="dis_pri" v-if="dcAmt > 0">{{ formatAmount(saleAmt) }}</span>
        <span class="dis_per" v-if="dcAmt > 0">{{ formatPercent(dcAmt / saleAmt) }}</span>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import numeral from "numeral";
import SpecialGoods from "../desktop/SpecialGoods.vue";

export default {
  extends: SpecialGoods,

  computed: {

  }
};
</script>
<style scoped>
.pro_sminfo .pro_name {
  font-size: 12px;
}
.pro_sminfo .price span {
  font-size: 12px;
}
</style>
