<template>
  <div>
    <component
      :is="component"
      :categories="categories"
      :mobile-intro="mobileIntro"
    />

    <div class="main-popup-modal" v-if="!$device.isMobile">
      <div
        class="modal"
        v-for="(popup, index) in popupList"
        :key="`popup_${index}`"
      >
        <!--<main-modal v-if="!popup.isClose" :html="popup.content" @disablePopup="handlerDisableEvent(popup)" @closePopup="closePopup(popup)" :style="getMainModalStyle(popup)"/>-->
        <main-modal :popupList="popupList" v-if="!popup.isClose && index == 0" :html="popup.content" @disablePopup="handlerDisableEvent(popup)" @closePopup="closePopup(popup)" :style="getMainModalStyle(popup)"/>
      </div>
    </div>

    <div class="main-popup-modal" v-if="$device.isMobile">
      <div
        class="modal"
        v-for="(popup, index) in popupList"
        :key="`popup_${index}`"
      >
        <!--<main-modal v-if="!popup.isClose" :html="popup.content" @disablePopup="handlerDisableEvent(popup)" @closePopup="closePopup(popup)" :style="getMainModalStyle(popup)"/>-->
        <main-mobile-modal :popupList="popupList" v-if="!popup.isClose && index == 0" :html="popup.content" @disablePopup="handlerDisableEvent(popup)" v-show="completeMobilePopRender" @closePopup="closePopup(popup)" :style="getMainMobileModalStyle(popup)"/>
      </div>
    </div>
  </div>
</template>

<script>
import Page from "@/assets/mixins/page";
import moment from "moment";
import Desktop from "@/components/pages/main/today/Desktop.vue";
import Mobile from "@/components/pages/main/today/Mobile.vue";
import MainModal from "@/components/commons/modal/MainModal.vue";
import MainMobileModal from "@/components/commons/modal/MainMobileModalNew.vue";

export default {
  components: {MainModal, Desktop, Mobile, MainMobileModal},

  mixins: [Page],

  beforeRouteEnter: (to, from, next) => {
    if (from.name) {
      to.meta.initialSlide = 1;
    }
    next();
  },

  async asyncData({$axios}) {
    try {
      const [categories, popupList] = await Promise.all([
        $axios.$get("/api/main/today-categories"),
        $axios.$get("/api/event/popup")
      ]);
      popupList.forEach(popup => {
        popup.isClose = false;
      });
      return {categories, popupList};
    } catch (e) {
      return {};
    }
  },

  data() {
    return {
      categories: [],
      mobileIntro: undefined,
      popupList: [],
      modalIndex: -1,
      moPopWidth: 300,
      moPopHeight: 430,
      completeMobilePopRender: false,
    };
  },

  fetch({store}) {
    store.commit("layout/setMobileHeader", {
      headerComponentName: "main",
      routeName: "Today",
      hasFooter: true,
    });
  },

  computed: {
    component() {
      return this.$device.isMobile ? "mobile" : "desktop";
    },
  },

  mounted() {
    let disableList = this.getDisabledPopup().map(x => x.popupNo);
    this.popupList = this.popupList.filter(x => disableList.indexOf(x.popupNo) < 0);
    if (this.$device.isMobile) {
      //this.openNextMainModal();
    }
    this.executeCriteoScript();
  },

  methods: {
    openNextMainModal() {
      let index = this.modalIndex + 1;
      if (index >= 0 && index < this.popupList.length) {
        this.modalIndex = index;
        let popup = this.popupList[index];
        let instance = this.$modal.show(MainMobileModal, {
          html: popup.content,
          backdrop: false,
          disablePopup: () => {
            this.handlerDisableEvent(popup);
          }
        }, {
          class: "v--modal-no-overlay",
          width: "300px",
          height: "430px"
        }, {
          closed: () => {
            this.openNextMainModal();
          }
        });
        // console.log(instance);
      }
    },

    getMainMobileModalStyle(popup) {
      // 모바일은 width : 300px , height: 430px 로 고정한다.
      var locationHorizontal = 15;
      var locationVertical = 15;
      var fixedWidth = this.moPopWidth;
      var fixedHeight = this.moPopHeight;
      try{
        if (typeof window !== "undefined") {

            var size = {
              width : window.innerWidth || document.body.clientWidth,
              height : window.innerHeight || document.body.clientHeight 
            };
            locationHorizontal = (size.width - fixedWidth) / 2 ;
            locationVertical = (size.height - fixedHeight) / 2 ;
            
        }
      } catch (e) {

      } finally {
        this.completeMobilePopRender = true;
      }

      return {
        "width": fixedWidth + "px",
        "height": fixedHeight + "px",
        "top": locationVertical + "px",
        "left": locationHorizontal + "px",
        "box-shadow": "0 20px 60px -2px rgba(27, 33, 58, 0.4)"
      };
    },

    closePopup (popup){
      popup.isClose = true;
    },

    handlerDisableEvent(popup) {
      let disabledList = this.getDisabledPopup();
      for(var i = 0; i < this.popupList.length; i++){
        disabledList.push({
          popupNo: this.popupList[i].popupNo,
          registeredDate: moment().format("YYYYMMDD")
        });
      }
      window.localStorage.setItem("NC_EVENT_POPUP", JSON.stringify(disabledList));
    },

    getDisabledPopup() {
      let disableList = [];
      try {
        disableList = JSON.parse(window.localStorage.getItem("NC_EVENT_POPUP") || "[]");
      } catch (e) {
        // disableList not exists
      }
      disableList = disableList.filter(x => x.registeredDate === moment().format("YYYYMMDD"));
      return disableList;
    },

    getMainModalStyle(popup) {

      var locationHorizontal = 15;
      var locationVertical = 15;
      try{
        if (typeof window !== "undefined") {
          if(popup.wdtSz && popup.heitSz && popup.pstLeft && popup.pstTop) {
            if(popup.pstLeft >= 0 && popup.pstLeft <= 100 && popup.pstTop >= 0 && popup.pstTop <= 100){
              var size = {
                width : window.innerWidth || document.body.clientWidth,
                height : window.innerHeight || document.body.clientHeight
              };

              locationHorizontal = popup.pstLeft - (popup.wdtSz / size.width / 2.0 * 100.0);
              locationVertical = popup.pstTop - (popup.heitSz / size.height / 2.0 * 100.0);
            }
          }
        }
      } catch (e) {}

      return {
        "width": popup.wdtSz + "px",
        "height": popup.heitSz + "px",
        "top": locationVertical + "%",
        "left": locationHorizontal + "%",
        "box-shadow": "0 20px 60px -2px rgba(27, 33, 58, 0.4)"
      };
    },
    executeCriteoScript() {

      try{

        if (typeof window !== "undefined") {
          window.criteo_q = window.criteo_q || [];
          var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
          window.criteo_q.push(
          { event: "setAccount", account: 95151},
          { event: "setEmail", email: "" },
          { event: "setZipcode", zipcode: "" },
          { event: "setSiteType", type: deviceType},
          { event: "viewHome"});
        }

      } catch (e) {

      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-popup-modal {
  .modal {
    position: fixed;
    z-index: 1000;
  }
}
</style>
